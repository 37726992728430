import styled from 'styled-components/macro';
import { rgba } from 'utils/hexToRgb';

export const StyledOverlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ theme }) => rgba(theme.colors.teleBlue60, 0.95)};
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: none;
    }
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        display: none;
    }
`;
export const StyledOverlayContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const StyledOverlayCircle = styled.div`
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 240px;
    min-height: 240px;
`;
export const StyledOverlayTitle= styled.div`
    margin: 1.2em 0 0 0;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
`;
export const StyledOverlayDescription = styled.div`
    margin: 5em 3.5em;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
export const StyledOverlayIcon = styled.div`
    border: 2px solid #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-bottom: 0.5em;
    display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;
