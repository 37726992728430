export const colors = {
  teleGreen: '#20A869',
  teleGreen10: '#EEF9F4',
  teleGreen40: '#0E7E4A',
  teleBlue60: '#012760',
  teleWhite: '#FFFFFF',
  teleBlack: '#3C3F3D',
  teleGray10: '#EBEBEB',
  teleGray20: '#C4C4C4',
  teleGray60: '#787878',
  teleBeige10: '#F6F5F2',
  black: '#000000',
  gray: '#6c6c6c'
}
