import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './PageWrapper.styles'

export const PageWrapper = ({ children }) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
};

PageWrapper.defaultProps = {
  children: {}
};