import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/theme';
import { Normalize } from 'styled-normalize';
import { GlobalStyle } from 'styles/globalStyles';
import { FontStyles } from 'styles/fontStyles';
import { AppProvider } from 'utils/AppProvider';
import TagManager from 'react-gtm-module';
import './locales/index';


const tagManagerArgs = {
  gtmId: 'GTM-TDSDRR'
}

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: 'https://443cee03871449b99bea9ed05bcaac23@o58425.ingest.sentry.io/6331152',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  // eslint-disable-next-line no-undef
  environment: process.env.NODE_ENV
});

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider theme={theme}>
        <Normalize />
        <FontStyles />
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
