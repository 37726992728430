import styled from 'styled-components/macro';

export const StyledSignWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const StyledSignPlaceholderWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledSignPlaceholder = styled.img`
    display: block;
    height: auto;
    max-width: 50%;
    
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
      max-width: 462px;
    }
`;
