import React from 'react';
import { StyledHeaderWrapper } from './HeaderWrapper.styles';
import PropTypes from 'prop-types';

export const HeaderWrapper = ({ children }) => {
  return (
    <StyledHeaderWrapper>{children}</StyledHeaderWrapper>
  )
}

HeaderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
};

HeaderWrapper.defaultProps = {
  children: ''
};
