import React from 'react';
import { useState, useEffect } from 'react';
import Sign from 'components/sign/Sign';
import { PageWrapper } from 'components/pageWrapper/PageWrapper';
import { StyledContentWrapper } from 'components/pageWrapper/PageWrapper.styles';
import { Button } from 'components/button/Button';
import Icon from 'assets/icon_turn-phone.png';
import Undo from 'assets/undo.svg';
import { Stepper } from 'components/stepper/Stepper';
import { HeaderWrapper } from 'components/headerWrapper/HeaderWrapper';
import { Subtitle } from 'components/subtitle/Subtitle';
import { Separator } from 'components/separator/Separator';
import { Title } from 'components/title/Title';
import { StyledTitleWrapper } from 'components/title/Title.styles';
import { IconWrapper } from 'components/iconWrapper/IconWrapper';
import { Description } from 'components/description/Description';
import { Footer } from 'components/footer/Footer';
import { Overlay } from 'components/overlay/Overlay';
import { DetailsWrapper } from 'components/detailsWrapper/DetailsWrapper';
import { useAppAPI } from 'utils/useAppAPI';
import { StaticPage } from 'components/staticPage/StaticPage';
import GeneralFailureImg from 'assets/general-failure_icon.png';
import SignedErrorImg from 'assets/declaration-signed.png';
import SuccessImg from 'assets/checkbox.png';
import { Loader } from 'components/loader/Loader';
import TagManager from 'react-gtm-module'
import {
  tagManagerChangePersonnelSucceededConfig,
  tagManagerDeclarationConfirmSucceededConfig,
  tagManagerMainConfig
} from './constants/tagManagerConfig';
import { useTranslation } from 'react-i18next';
import { LanguageSwitch } from './components/languageSwitch/LanguageSwitch';


function App() {
  const appAPI = useAppAPI();
  const [staticPageData, setStaticPageData] = useState({
    imageSrc: '',
    title: '',
    description: '',
    button: false,
    link: false,
  });
  const { i18n } = useTranslation();


  TagManager.dataLayer(tagManagerMainConfig);

  const thankForYourDeclaration = (
    <span dangerouslySetInnerHTML={{ __html: i18n.t('app.declarationThankYou') }} />
  )

  useEffect(()=>{
    if(appAPI.sent) {
      document.querySelector('body').style.position = 'initial';
      document.querySelector('body').style.overflow = 'auto';
    } else {
      document.querySelector('body').style.position = 'fixed'
      document.querySelector('body').style.overflow = 'hidden';
    }
  },[appAPI.sent])

  const handlePrimaryButtonClickAction = () => {
    const URLParams = new URLSearchParams(window.location.search);
    const URLParamKey = 'changePersonnel';
    const shouldUpdateSignatureOnly =
      URLParams.has(URLParamKey) &&
      URLParams.get(URLParamKey) === 'true';

    if (shouldUpdateSignatureOnly) {
      changePersonnel();
    } else {
      sendSignature();
    }
  }

  const sendSignature = () => {
    appAPI.sendSignature()
      .then(() => {
        TagManager.dataLayer(tagManagerDeclarationConfirmSucceededConfig);
        setStaticPageData({
          imageSrc: SuccessImg,
          title: i18n.t('app.sendSignatureSuccess'),
          description: thankForYourDeclaration,
          button: false,
          link: false,
        })
      })
      .catch((error) => {
        if(error?.response?.data?.errorType === 13) {
          setStaticPageData({
            imageSrc: SignedErrorImg,
            title: i18n.t('app.sendSignatureWeAlreadyHaveYourSignatureErrorTitle'),
            description:  i18n.t('app.sendSignatureWeAlreadyHaveYourSignatureErrorMessage'),
            button: false,
            link: true,
          })
          return;
        }

        setStaticPageData({
          imageSrc: GeneralFailureImg,
          title: i18n.t('app.sendSignatureGeneralFailureErrorTitle'),
          description:  i18n.t('app.sendSignatureGeneralFailureErrorMessage'),
          button: true,
          link: false,
        })
      })
  }

  const changePersonnel = () => {
    appAPI.changePersonnel()
      .then(() => {
        TagManager.dataLayer(tagManagerChangePersonnelSucceededConfig);
        setStaticPageData({
          imageSrc: SuccessImg,
          title: i18n.t('app.sendSignatureSuccess'),
          description: thankForYourDeclaration,
          button: false,
          link: false,
        })
      })
      .catch(() => {
        setStaticPageData({
          imageSrc: GeneralFailureImg,
          title: i18n.t('app.sendSignatureGeneralFailureErrorTitle'),
          description:  i18n.t('app.sendSignatureGeneralFailureErrorMessage'),
          button: true,
          link: false,
        })
      })
  }

  return (
    <PageWrapper>
      {appAPI.sent
        ? <StaticPage
          iconSrc={staticPageData.imageSrc}
          title={staticPageData.title}
          description={staticPageData.description}
          button={staticPageData.button}
          link={staticPageData.link} />
        : (
          <StyledContentWrapper>
            <HeaderWrapper>
              <Subtitle><p dangerouslySetInnerHTML={{ __html: i18n.t('app.subtitle') }} /></Subtitle>
              <LanguageSwitch />
              <Stepper />
            </HeaderWrapper>
            <Separator />
            <StyledTitleWrapper>
              { appAPI.processing && <Loader /> }
              <Title>{i18n.t('app.title')}</Title>
            </StyledTitleWrapper>
            <IconWrapper main icon={Icon} />
            <Description light>{i18n.t('app.description')}</Description>
            <Sign />
            <DetailsWrapper>
              <Description>{i18n.t('app.descriptionMore')}</Description>
              <LanguageSwitch customClassName="hide-desktop" />
              {!appAPI.processing && appAPI.isAppropriateSignatureSize && <IconWrapper undo icon={Undo} onClickHandler={appAPI.undoLastLine} />}
              <Button reversed onClickHandler={handlePrimaryButtonClickAction} disabled={appAPI.processing || !appAPI.isAppropriateSignatureSize}>{i18n.t('app.sendDeclaration')}</Button>
              <Button light onClickHandler={appAPI.clearBoard}>{i18n.t('app.clear')}</Button>
            </DetailsWrapper>
            <Footer />
            <Overlay />
          </StyledContentWrapper>
        )}
    </PageWrapper>
  );
}

export default App;
