import React from 'react';
import {
  StyledOverlay,
  StyledOverlayContent,
  StyledOverlayCircle,
  StyledOverlayTitle,
  StyledOverlayDescription,
  StyledOverlayIcon
} from './Overlay.styles';
import { IconWrapper } from 'components/iconWrapper/IconWrapper';
import Icon from 'assets/icon_turn-phone.png';
import { useTranslation } from 'react-i18next';

export const Overlay = () => {
  const { i18n } = useTranslation()
  return (
    <StyledOverlay>
      <StyledOverlayContent>
        <StyledOverlayCircle>
          <IconWrapper center icon={Icon} />
        </StyledOverlayCircle>
        <StyledOverlayTitle>
          {i18n.t('overlay.rotatePhoneTitle')}
        </StyledOverlayTitle>
        <StyledOverlayDescription>
          <StyledOverlayIcon>!</StyledOverlayIcon>
          <span dangerouslySetInnerHTML={{ __html: i18n.t('overlay.rotatePhoneDescription') }} />
        </StyledOverlayDescription>
      </StyledOverlayContent>
    </StyledOverlay>
  )
}
