import styled from 'styled-components/macro';

export const StyledSeparator = styled.div`
    background: ${({ theme }) => theme.colors.teleGray10};
    width: 100vw;
    height: 2px;
    margin: 0 -1em;
    @media ${({ theme }) => theme.breakpoints.small} {
        margin: 0 -2em;
    }
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        width: 100%;
        margin: 0;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: none;
    }
`;