import styled  from 'styled-components/macro';
import flagPL from '../../assets/flags/pl.svg'
import flagEN from '../../assets/flags/en.svg'
import flagUA from '../../assets/flags/ua.svg'
import flagES from '../../assets/flags/es.svg'
import arrowUp from '../../assets/dropdown-arrow-up.svg'
import arrowDown from '../../assets/dropdown-arrow-down.svg'

export const StyledLanguageSwitch = styled.div`
  .Dropdown-root {
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.teleBlack};
    &.is-open {
      .Dropdown-arrow {
        background: url(${arrowUp});
        background-size: 100% 100%;
      }
    }
    &.language-en {
      .Dropdown-placeholder {
        &::before {
          background: url(${flagEN});
          background-size: 100% 100%;
        }
      }
    }
    &.language-es {
      .Dropdown-placeholder {
        &::before {
          background: url(${flagES});
          background-size: 100% 100%;
        }
      }
    }
    &.language-ua {
      .Dropdown-placeholder {
        &::before {
          background: url(${flagUA});
          background-size: 100% 100%;
        }
      }
    }
    &.hide-desktop {
      display: none;
      @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: block;
      }
    }
  }
  .Dropdown-control {
    background: ${({ theme }) => theme.colors.teleBeige10};
    border-color: transparent;
    border-radius: 8px;
    .Dropdown-placeholder {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        width: 24px;
        height: 20px;
        background: url(${flagPL});
        background-size: 100% 100%;
        display: inline-block;
        margin-right: 8px;
      }
    }
    .Dropdown-arrow {
      border: none;
      background: url(${arrowDown});
      background-size: 100% 100%;
      width: 10px;
      height: 5px;
    }
  }
  .Dropdown-menu {
    border-color: transparent;
    border-radius: 8px;
    @media screen and (orientation:landscape) and (max-width: 1023px) {
      bottom: 100%;
      top: unset;
      margin-top: unset;
      margin-bottom: -1px;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
    }
    
  }
  .Dropdown-option {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 24px;
      height: 20px;
      background: url(${flagPL});
      background-size: 100% 100%;
      display: inline-block;
      margin-right: 8px;
    }
    &.language-en {
      &::before {
        background: url(${flagEN});
        background-size: 100% 100%;
      }
    }
    &.language-es {
      &::before {
        background: url(${flagES});
        background-size: 100% 100%;
      }
    }
    &.language-ua {
      &::before {
        background: url(${flagUA});
        background-size: 100% 100%;
      }
    }
    &.is-selected {
      background-color: transparent;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.teleGreen10};
    }
  }
`;
