import styled from 'styled-components/macro';

export const StyledFooter = styled.div`
    display: flex;
    align-self: center;
    font-size: 13px;
    margin: 1em 0;
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        display: none;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: none;
    }
`;
export const StyledLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.teleGray60};
`;