import React from 'react';
import PropTypes from 'prop-types';
import { StyledDetailsWrapper } from './DetailsWrapper.styles';

export const DetailsWrapper = ({ children }) => {
  return (
    <StyledDetailsWrapper id="detailsWrapper">
      {children}
    </StyledDetailsWrapper>
  )
}

DetailsWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
};

DetailsWrapper.defaultProps = {
  children: {}
};