import styled from 'styled-components/macro';

export const StyledSubtitle = styled.h2`
    display: none;
    font-size: 1.15em;
    font-weight: 700;
    margin-right: 1.5em;
    color: ${({ theme }) => theme.colors.teleBlack};
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        display: block;
    }
    & span {
        color: ${({ theme }) => theme.colors.teleGreen};
    }
    & p {
      margin: 0;
    }
`;
