import React, { useEffect, useState, useRef } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { isMobile } from 'utils/isMobile';
import { getOrientationMode } from 'utils/getOrientationMode';
import { StyledSignWrapper, StyledSignPlaceholder, StyledSignPlaceholderWrapper } from './Sign.styles';
import { useAppAPI } from 'utils/useAppAPI';
import SignPlaceholderPL from 'assets/sign-placeholder-pl.png';
import SignPlaceholderEN from 'assets/sign-placeholder-en.png';
import SignPlaceholderES from 'assets/sign-placeholder-es.png';
import SignPlaceholderUA from 'assets/sign-placeholder-ua.png';
import { useTranslation } from 'react-i18next';

const Sign = () => {
  const appAPI = useAppAPI();
  const tool = 'pen';
  const isDrawing = useRef(false);
  const containerRef = useRef(null);
  const stageRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(getOrientationMode());
  const [detailsHeight, setDetailsHeight] = useState(0)
  const [size, setSize] = useState({
    width: 0,
    height: isLandscape
      ? 220
      : isMobile() ? 120 : 300,
    scale: {
      x: 1,
      y: 1
    }
  })

  const detailsWrapper = document.getElementById('detailsWrapper')

  useEffect(()=>{
    detailsWrapper && setDetailsHeight(detailsWrapper.getBoundingClientRect().height)
  },[detailsWrapper])

  const getRelativePointerPosition = (node) => {
    const transform = node.getAbsoluteTransform().copy();
    transform.invert();
    const pos = node.getStage().getPointerPosition();
    return transform.point(pos);
  }

  const handleMouseDown = (e) => {
    document.querySelector('body').style.overflow = 'hidden';
    isDrawing.current = true;
    const pos = getRelativePointerPosition(e.target.getStage());
    appAPI.setLines([...appAPI.lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = getRelativePointerPosition(stage);
    let lastLine = appAPI.lines[appAPI.lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    appAPI.lines.splice(appAPI.lines.length - 1, 1, lastLine);
    appAPI.setLines(appAPI.lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(()=>{
    const initialWidth = containerRef.current.getBoundingClientRect().width;
    const initialHeight = containerRef.current.getBoundingClientRect().height;
    const checkSize = () => {
      const containerScale = containerRef.current.getBoundingClientRect().width / initialWidth;
      const containerWidth = initialWidth * containerScale;
      const containerHeight = initialHeight * containerScale;

      setIsLandscape(getOrientationMode());
      setSize({
        width: containerWidth,
        height: containerHeight,
        scale: {
          x: containerScale,
          y: containerScale
        }
      })
    };

    checkSize();
    window.addEventListener('resize', checkSize);
    window.addEventListener('orientationchange', checkSize);
    return () => {
      window.removeEventListener('resize', checkSize);
      window.removeEventListener('orientationchange', checkSize);
    };
  },[])

  useEffect(() => {
    let linesWithoutDuplicatedPoints = [];
    let lineWithoutDuplicates = [];
    appAPI.lines.forEach((el) => {
      const splittedPoints = el.points.reduce(function(result, value, index, array) {
        if (index % 2 === 0)
          result.push(array.slice(index, index + 2));
        return result;
      }, []);
      lineWithoutDuplicates = Array.from(new Set(splittedPoints.map(JSON.stringify)), JSON.parse);
      linesWithoutDuplicatedPoints.push(lineWithoutDuplicates);
    });
    const linesLongerThanMin = linesWithoutDuplicatedPoints.filter( line => line.length >= 10);
    const assumptionFulfilled = appAPI.lines.length >= 2 && linesLongerThanMin.length >= 2;
    const anyLineLongerThanMinimum = linesWithoutDuplicatedPoints.some( line => line.length >= 15);

    assumptionFulfilled || anyLineLongerThanMinimum
      ? appAPI.setIsAppropriateSignatureSize(true)
      : appAPI.setIsAppropriateSignatureSize(false);

    appAPI.setCanvasStage(stageRef);
  });
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getSignaturePlaceholderByLanguage = (language) => {
    switch(language) {
    case 'pl': return SignPlaceholderPL;
    case 'en': return SignPlaceholderEN;
    case 'es': return SignPlaceholderES;
    case 'ua': return SignPlaceholderUA;
    }
  }

  return (
    <StyledSignWrapper style={{ maxHeight: size.height - detailsHeight }} ref={containerRef}>
      {!isDrawing.current && !appAPI.lines.length &&
        (
          <StyledSignPlaceholderWrapper>
            <StyledSignPlaceholder src={getSignaturePlaceholderByLanguage(currentLanguage)} alt="sign-placeholder"/>
          </StyledSignPlaceholderWrapper>
        )}
      <Stage
        ref={stageRef}
        style={{ border: '1px solid #DCDCDC' }}
        width={size.width}
        height={size.height - detailsHeight}
        scaleX={size.scale.x}
        scaleY={size.scale.y}

        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <Layer>
          {appAPI.lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="#3C3F3D"
              strokeWidth={2}
              tension={0.1}
              lineCap="round"
              globalCompositeOperation={'source-over'}
            />
          ))}
        </Layer>
      </Stage>
    </StyledSignWrapper>
  );
};

export default Sign;
