import styled from 'styled-components/macro';

export const StyledDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 50px;
    margin-top: 1em;
    & > p {
        align-self: flex-start;
    }
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;