import styled, { keyframes } from 'styled-components/macro';

const spin = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`;

export const StyledLoader = styled.div`
    width: 36px;
    height: 18px;
    border-top-left-radius: 110px;  /* 100px of height + 10px of border */
    border-top-right-radius: 110px; /* 100px of height + 10px of border */
    border: 4px solid ${({ theme }) => theme.colors.teleGreen};
    border-bottom: none;
    position: relative;
    top: -9px; // half of height
    margin: 0 1em 0 0;
    animation-name: ${spin};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 50% 100%;
`;