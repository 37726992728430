import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './Button.styles';

export const Button = ({ children, onClickHandler, light, reversed, disabled }) => {
  return (
    <StyledButton
      onClick={onClickHandler}
      light={light}
      reversed={reversed}
      disabled={disabled}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  light: PropTypes.bool,
  reversed: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  children: '',
  onClickHandler: () => {},
  light: false,
  reversed: false,
  disabled: false
};