import styled from 'styled-components/macro';
import { rgba } from 'utils/hexToRgb';

export const StyledContainer = styled.div`
    max-width: 1264px;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.teleWhite};
    box-shadow: 0 0 52px ${({ theme }) => rgba(theme.colors.gray, 0.09)};
    box-sizing: border-box;
    overflow: hidden;
    padding: 0.5em .8em;
    @media ${({ theme }) => theme.breakpoints.small} {
        padding: 0.5em 2em;
        min-height: 100vh;
    }
    @media screen and (orientation:landscape) and (min-width: 1024px) {
        margin: 5em auto;
        border-radius: 1.75em;
        min-height: unset;
    }
     @media screen and (orientation:landscape) and (max-width: 1024px) {
        padding: 0.3em 2em;
    }
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (orientation:landscape) and (min-width: 1024px) {
        margin: 0em 4em 4em 4em;
    }
`;
