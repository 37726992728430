import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { StyledLanguageSwitch } from './LanguageSwitch.styles';
import PropTypes from 'prop-types';


export const LanguageSwitch = ({ customClassName }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const availableLanguages = {
    PL: 'pl',
    EN: 'en',
    ES: 'es',
    UA: 'ua',
  }
  const dropdownOptions = Object.values(availableLanguages);
  const dropdownOptionsObject = dropdownOptions.map((value) => ({ value, label: value, className: `language-${value}` }));
  const selectedOption = dropdownOptions[dropdownOptions.indexOf(currentLanguage)]

  const setLanguage = (language) => {
    if (language !== currentLanguage) {
      i18n
        .changeLanguage(language);
      i18n
        .setDefaultNamespace(language);
    }
  }
  return (
    <StyledLanguageSwitch>
      <Dropdown
        options={dropdownOptionsObject}
        onChange={(e) => setLanguage(e.value)}
        value={selectedOption}
        placeholder={i18n.t('languageSwitch.languageDropdownPlaceholder')}
        className={`${customClassName} language-${currentLanguage}`}
      />
    </StyledLanguageSwitch>
  )
}

LanguageSwitch.propTypes = {
  customClassName: PropTypes.string,
}

LanguageSwitch.defaultProps = {
  customClassName: '',
}
