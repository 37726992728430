import React from 'react';
import { StyledFooter, StyledLink } from './Footer.styles';
import { IconWrapper } from 'components/iconWrapper/IconWrapper';
import LockIcon from 'assets/lock.svg';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { i18n } = useTranslation();
  return (
    <StyledFooter>
      <IconWrapper inline icon={LockIcon} />
      <StyledLink href="https://telemedi.com/privacy-policy/" target="_blank" rel="noreferrer">{i18n.t('footer')}</StyledLink>
    </StyledFooter>
  )
}
