import React from 'react';
import PropTypes from 'prop-types';
import { StyledContentWrapper } from 'components/pageWrapper/PageWrapper.styles';
import { StyledStaticPage, StyledIcon, StyledTitle, StyledDescription } from './StaticPage.styles';
import { Stepper } from 'components/stepper/Stepper';
import { HeaderWrapper } from 'components/headerWrapper/HeaderWrapper';
import { Separator } from 'components/separator/Separator';
import { Button } from 'components/button/Button';
import { useAppAPI } from 'utils/useAppAPI';
import { useTranslation } from 'react-i18next';

export const StaticPage = ({ iconSrc, title, description, button, link }) => {
  const appAPI = useAppAPI();
  const { i18n } = useTranslation();

  const goBack = () => {
    appAPI.clearBoard();
    appAPI.setSent(false);
  }

  return (
    <StyledContentWrapper>
      <HeaderWrapper>
        <Stepper success />
      </HeaderWrapper>
      <Separator />
      <StyledStaticPage>
        <StyledIcon>
          <img src={iconSrc} alt=''/>
        </StyledIcon>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        {button && <Button onClickHandler={goBack}>{i18n.t('staticPage.sendDeclaration')}</Button>}
        {link && <Button onClickHandler={() => window.close()}>{i18n.t('staticPage.close')}</Button>}
      </StyledStaticPage>
    </StyledContentWrapper>
  )
}

StaticPage.propTypes = {
  iconSrc: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.bool,
  link: PropTypes.bool,
};

StaticPage.defaultProps = {
  iconSrc: {},
  title: '',
  description: '',
  button: false,
  link: false,
};
