import styled from 'styled-components/macro';

export const StyledStepper = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.7em 0 1.2em 0;
    padding: 0;
    @media ${({ theme }) => theme.breakpoints.small} {
        margin-left: auto;
    }
`;
export const StyledStepperElement = styled.li`
    font-size: 0.75em;
    color: ${({ theme }) => theme.colors.teleBlack};
    display: flex;
    align-items: center;
    font-weight: 600;
    @media ${({ theme }) => theme.breakpoints.small} {
        margin: 0 0 0 1em;
    }
    & span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        color: ${({ theme }) => theme.colors.teleWhite};
        font-size: 13px;
        font-weight: 700;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.teleGreen40};
        margin: 0 .3em 0 0;
    }
    &:last-child span {
        background: ${({ theme, success }) => success || theme.colors.teleGray20};
    }
`;
