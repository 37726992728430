import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pl from './translations/pl.json';
import en from './translations/en.json';
import es from './translations/es.json';
import ua from './translations/ua.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  pl: {
    pl,
  },
  en: {
    en,
  },
  es: {
    es,
  },
  ua: {
    ua,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: 'pl',
    defaultNS: 'pl',
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: true,
    },
    resources,
  });

export default i18n;
