export const tagManagerMainConfig = {
  dataLayer: {
    event: 'widgetStep_vPOZ_Signature_Box',
    product: 'web',
    page: 'home',
    platform: 'widget_vPOZ',
    user_affiliation: 'Telemedi',
  },
  dataLayerName: 'dataLayer',
};

export const tagManagerSignatureFailedConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_Signature_Failed',
  },
};

export const tagManagerSignatureSucceededConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_vPOZ_Thank_You_Mobile',
  },
};

export const tagManagerDeclarationConfirmSucceededConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_vPOZ_Thank_You_Desktop',
  }
}

export const tagManagerChangePersonnelSucceededConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_vPOZ_Change_Personnel_Thank_You_Desktop',
  }
}

export const tagManagerDeclarationConfirmFailedConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_Declaration_Failed',
  },
};

export const tagManagerChangePersonnelFailedConfig = {
  ...tagManagerMainConfig,
  dataLayer: {
    ...tagManagerMainConfig.dataLayer,
    event: 'widgetStep_Change_Personnel_Failed',
  },
};
