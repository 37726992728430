import styled from 'styled-components/macro';

export const StyledDescription = styled.p`
    font-size: 0.875em;
    line-height: 1.5em;
    margin: ${({ light }) => light ? '1.5em 0' : '1.5em auto 0 0'};
    color: ${({ theme }) => theme.colors.black};
    color: ${({ theme, light }) => light && theme.colors.teleBlack};
    max-width: ${({ light }) => !light && '350px'};
    margin-bottom: 50px;
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        display: ${({ light }) => light && 'none'};
        margin: ${({ light }) => light ? '1.5em 0' : '0 auto 0 0'};
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: ${({ light }) => light && 'none'};
        margin: ${({ light }) => light ? '1.5em 0' : '0 auto 0 0'};
    }
    & span {
        font-weight: 700;
    }
`;
