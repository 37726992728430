import React from 'react';
import { StyledStepper, StyledStepperElement } from './Stepper.styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Stepper = ({ success = false }) => {
  const { i18n } = useTranslation()
  return (
    <StyledStepper>
      <StyledStepperElement><span>1</span>{i18n.t('stepper.account')}</StyledStepperElement>
      <StyledStepperElement><span>2</span>{i18n.t('stepper.data')}</StyledStepperElement>
      <StyledStepperElement><span>3</span>{i18n.t('stepper.signature')}</StyledStepperElement>
      <StyledStepperElement success={success}><span>4</span>{i18n.t('stepper.confirmation')}</StyledStepperElement>
    </StyledStepper>
  )
}

Stepper.propTypes = {
  success: PropTypes.bool,
};
