import React from 'react';
import { StyledIconWrapper } from './IconWrapper.styles';
import PropTypes from 'prop-types';

export const IconWrapper = ({ onClickHandler, icon, inline, main, undo }) => {
  return (
    <StyledIconWrapper onClick={onClickHandler} undo={undo} inline={inline} main={main}>
      <img src={icon} alt=''/>
    </StyledIconWrapper>
  )
}

IconWrapper.propTypes = {
  onClickHandler: PropTypes.func,
  icon: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  main: PropTypes.bool,
  undo: PropTypes.bool
};

IconWrapper.defaultProps = {
  onClickHandler: () => {},
  icon: '',
  inline: false,
  main: false,
  undo: false
};
