import React from 'react';
import { StyledTitle } from './Title.styles';
import PropTypes from 'prop-types';

export const Title = ({ children }) => {
  return (
    <StyledTitle>{children}</StyledTitle>
  )
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ])
};

Title.defaultProps = {
  children: ''
};
