import React from 'react';
import { StyledDescription } from './Description.styles';
import PropTypes from 'prop-types';

export const Description = ({ children, light }) => {
  return (
    <StyledDescription light={light}>{children}</StyledDescription>
  )
}

Description.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ]),
  light: PropTypes.bool
};

Description.defaultProps = {
  children: '',
  light: false
};
