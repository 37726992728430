import styled from 'styled-components/macro';

export const StyledTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: none;
    }
`;

export const StyledTitle = styled.h1`
    font-size: 1.25em;
    font-weight: 700;
    margin: 1.2em 0;
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        font-size: 2.25em;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: none;
    }
`;