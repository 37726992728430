import styled, { css } from 'styled-components/macro';

export const StyledIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 160px;
    margin: 0 0 .5em 0;
    margin: ${({ inline }) => inline && '0 .5em 0 0'};
    ${ ({ main }) => main && css`
        align-self: center;
        width: 45%;
    `}
    display: ${({ undo }) => undo && 'none'};
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        display: ${({ undo }) => !undo ? 'none' : 'flex'};
        margin: ${({ undo }) => undo && '0 1em'};
        min-width: ${({ undo }) => undo && '1.125em'};
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        display: ${({ undo }) => !undo ? 'none' : 'flex'};
        margin: ${({ undo }) => undo && '0 1em'};
        min-width: ${({ undo }) => undo && '1.125em'};
    }
    & img {
        width: 100%;
    }
`;