import React from 'react';
import { StyledSubtitle } from './Subtitle.styles';
import PropTypes from 'prop-types';

export const Subtitle = ({ children }) => {
  return (
    <StyledSubtitle>{children}</StyledSubtitle>
  )
}

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired,
    PropTypes.node
  ])
};

Subtitle.defaultProps = {
  children: ''
};
