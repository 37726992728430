import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Ping Regular 4',  sans-serif;
        background: #efefef;
        overflow: hidden;
        width: 100%;
        @media screen and (orientation:landscape) and (max-width: 1023px) {
            overflow: hidden;
            height: 100%;
            position: fixed;
        }
        @media ${({ theme }) => theme.breakpoints.semiTablet} {
            overflow: auto;
        }
    }
`;
