import { createGlobalStyle } from 'styled-components';

export const FontStyles = createGlobalStyle`
@font-face {
    font-family: "Ping Regular 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002844-592567efceab69f64c9d65225e4009a2.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002844-592567efceab69f64c9d65225e4009a2.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: auto;
}
@font-face {
    font-family: "Ping 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002844-592567efceab69f64c9d65225e4009a2.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002844-592567efceab69f64c9d65225e4009a2.woff") format("woff");
    font-display: auto;
}
@font-face {
    font-family: "Ping Medium 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002846-db92da040df5d7d9b04bffa4442b4654.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002846-db92da040df5d7d9b04bffa4442b4654.woff") format("woff");
    font-display: auto;
}
@font-face {
    font-family: "Ping 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002846-db92da040df5d7d9b04bffa4442b4654.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002846-db92da040df5d7d9b04bffa4442b4654.woff") format("woff");
    font-style: normal;
    font-weight: 500;
    font-display: auto;
}
@font-face {
    font-family: "Ping Bold 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002848-3e70bbf76b21923033b26d6936d5176d.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002848-3e70bbf76b21923033b26d6936d5176d.woff") format("woff");
    font-display: auto;
}
@font-face {
    font-family: "Ping 4";
    src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002848-3e70bbf76b21923033b26d6936d5176d.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-036568-011668-002848-3e70bbf76b21923033b26d6936d5176d.woff") format("woff");
    font-style: normal;
    font-weight: 700;
    font-display: auto;
}
`;

export default FontStyles;