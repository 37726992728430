import styled from 'styled-components/macro';

export const StyledStaticPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 99;
    margin: 1em 0;
`;

export const StyledIcon = styled.div`
    width: 50%;
    max-width: 230px;
    margin: 3em 0 2em 0;
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        margin-top: 0;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        max-width: 150px;
        margin: 1em 0;
    }
    & img {
        width: 100%;
    }
`;

export const StyledTitle = styled.h1`
    font-size: 1.5em;
    align-self: flex-start;
    @media ${({ theme }) => theme.breakpoints.small} {
        align-self: center;
    }
`;

export const StyledDescription = styled.p`
    font-size: 1em;
    line-height: 1.5em;
    align-self: flex-start;
    @media ${({ theme }) => theme.breakpoints.small} {
        align-self: center;
    }
`;