import styled, { css } from 'styled-components/macro';
import { rgba } from 'utils/hexToRgb';

export const StyledButton = styled.button`
    color: ${({ theme }) => theme.colors.teleWhite};
    background-color: ${({ theme }) => theme.colors.teleGreen};
    font-weight: 700;
    font-size: 1em;
    line-height: 150%;
    border-radius: 1.5em;
    outline: none;
    border: 2px solid transparent;
    padding: .5em 2em;
    margin-top: 1.25em;
    cursor: pointer;
    transition: .15s cubic-bezier(0.66, 0.01, 0.86, 1);
    margin-right: .5em;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        order: ${({ light, reversed }) => !light && reversed && '1'};
        margin-top: 0;
    }
    @media screen and (orientation:landscape) and (max-width: 1023px) {
        order: ${({ light, reversed }) => !light && reversed && '1'};
        margin-top: 0;
    }
    &:visited {
        color: ${({ theme }) => theme.colors.teleWhite};
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.teleGreen40};
        box-shadow: 
            0px 2px 3px ${({ theme }) => rgba(theme.colors.teleGreen40, 0.2)},
            0px 4px 8px ${({ theme }) => rgba(theme.colors.teleGreen40, 0.6)};
        color: ${({ theme }) => theme.colors.teleWhite};
    }
    &:focus {
        box-shadow: 
            0px 2px 3px ${({ theme }) => rgba(theme.colors.teleGreen, 0.2)},
            0px 4px 8px ${({ theme }) => rgba(theme.colors.teleGreen, 0.6)};
        color: ${({ theme }) => theme.colors.teleWhite};
    }
    &:active {
        background-color: ${({ theme }) => theme.colors.teleGreen40};
        color: ${({ theme }) => theme.colors.teleWhite};
    }
    ${ ({ light }) => light && css`
        color: ${({ theme }) => theme.colors.teleGreen40};
        background: transparent;
        border: 2px solid ${({ theme }) => theme.colors.teleGreen40};
        box-shadow: none;
        &:focus { color: ${({ theme }) => theme.colors.teleGreen40}; }
        &:focus:hover { color: ${({ theme }) => theme.colors.teleWhite}; }
    `}
    ${ ({ disabled }) => disabled && css`
        pointer-events: none;
        background: ${({ theme }) => theme.colors.teleGray20};
    `}
`;
