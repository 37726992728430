const sizes = {
  small: '576px',
  semiTablet: '768px',
  tablet: '864px',
  semiMedium: '991px',
  medium: '1200px',
  desktop: '1440px',
  full: '1920px'
}

export const breakpoints = {
  small: `(min-width: ${sizes.small})`,
  semiTablet: `(min-width: ${sizes.semiTablet})`,
  tablet: `(min-width: ${sizes.tablet})`,
  semiMedium: `(min-width: ${sizes.semiMedium})`,
  medium: `(min-width: ${sizes.medium})`,
  desktop: `(min-width: ${sizes.desktop})`,
  full: `(min-width: ${sizes.full})`,
};
