import React, { useEffect, useState } from 'react';
import API from 'api/api';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import {
  tagManagerChangePersonnelFailedConfig,
  tagManagerDeclarationConfirmFailedConfig,
  tagManagerSignatureFailedConfig,
  tagManagerSignatureSucceededConfig
} from '../constants/tagManagerConfig';
import AddSignatureFailedError from 'errors/AddSignatureFailedError';
import ConfirmDeclarationFailedError from 'errors/ConfirmDeclarationFailedError';
import PropTypes from 'prop-types';
import ChangePersonnelFailedError from '../errors/ChangePersonnelFailedError';

export const AppProviderContext = React.createContext({});

export const AppProvider = ({ children }) => {
  const [lines, setLines] = useState([]);
  const [canvasStage, setCanvasStage] = useState(null);
  const [declarationToken, setDeclarationToken] = useState('');
  const [sent, setSent] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isAppropriateSignatureSize, setIsAppropriateSignatureSize] = useState(false);

  useEffect(()=>{
    getTokenFromURL();
  },[])

  const getTokenFromURL = () => {
    const splittedURL = window.location.pathname.split('/');
    const token = splittedURL[splittedURL.length-1];
    setDeclarationToken(token);
  }

  const undoLastLine = () => setLines(lines.slice(0, -1));

  const clearBoard = () => setLines([]);

  const getImgBase64 = () => {
    return canvasStage.current.toDataURL();
  }


  const sendSignature = () => {
    const signature = getImgBase64();
    setProcessing(true);
    return API.put('/api/v2/vpoz/declarations/signature/add', {
      signature,
      declarationToken
    })
      .then(() => {
        TagManager.dataLayer(tagManagerSignatureSucceededConfig);
        return confirmDeclaration()
      })
      .catch(error => {
        let truncatedErrorResponse = error.response;
        delete truncatedErrorResponse.signature;
        TagManager.dataLayer(tagManagerSignatureFailedConfig);
        setSent(true);
        setProcessing(false);
        Sentry.captureException(new AddSignatureFailedError(error), { extra: { data: truncatedErrorResponse } });
        return Promise.reject(new AddSignatureFailedError(error));
      })
  }

  const confirmDeclaration = () => {
    return API.post('/api/v2/vpoz/declarations/confirm', {
      declarationToken
    })
      .then((response) => {
        setSent(true);
        setProcessing(false);
        return Promise.resolve(response);
      })
      .catch((error) => {
        TagManager.dataLayer(tagManagerDeclarationConfirmFailedConfig);
        setSent(true);
        setProcessing(false);
        Sentry.captureException(new ConfirmDeclarationFailedError(error), { extra: { data: error.response } });
        return Promise.reject(new ConfirmDeclarationFailedError(error));
      })
  }

  const changePersonnel = () => {
    const signature = getImgBase64();
    return API.post(`/api/v2/vpoz/declarations/${declarationToken}/change-personnel`, {
      signature,
    })
      .then((response) => {
        setSent(true);
        setProcessing(false);
        return Promise.resolve(response);
      })
      .catch(error => {
        let truncatedErrorResponse = error.response;
        delete truncatedErrorResponse.signature;
        TagManager.dataLayer(tagManagerChangePersonnelFailedConfig);
        setSent(true);
        setProcessing(false);
        Sentry.captureException(new ChangePersonnelFailedError(error), { extra: { data: truncatedErrorResponse } });
        return Promise.reject(new ChangePersonnelFailedError(error));
      })
  }


  const getContext = () => ({
    lines,
    setLines,
    undoLastLine,
    clearBoard,
    canvasStage,
    setCanvasStage,
    sendSignature,
    sent,
    setSent,
    processing,
    isAppropriateSignatureSize,
    setIsAppropriateSignatureSize,
    changePersonnel
  })
  return (
    <AppProviderContext.Provider value={getContext()}>
      {children}
    </AppProviderContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};
